import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { PageProps, graphql } from "gatsby";

import { sanityClient } from "@lib/sanityClient";
import { Query, SanityProduct } from "@graphql-types";
import Layout from "@shared/layout";
import { Hero, Link, ProductCard, StandardGridLayout } from "@global";
import { calculateGridItemBorders, isBrowser } from "@util/helper";
import { Container } from "@util/standard";
import SEO from "@shared/seo";
import { assets } from "@util/constants";

interface Props extends PageProps {
  data: Query;
}

const StyledLink = styled(Link)`
  white-space: normal;
`;

function MixAndMatch({ data }: Props) {
  if (data.sanityMixMatchPage == null) {
    return null;
  }
  const { seo, hero } = data.sanityMixMatchPage;
  const [products, setProducts] = useState<SanityProduct[]>();
  const [loaded, setLoaded] = useState(false);

  // HANDLE URL PARAMETERS
  const queryString = isBrowser() && window.location.search;
  const urlParams = queryString && new URLSearchParams(queryString);

  if (!urlParams || products?.length === 0) {
    return (
      <h3 style={{ textAlign: "center", margin: "130px 0" }}>No similar Mix and Match Products</h3>
    );
  }

  const collection = urlParams.get("collection");
  const collectionSlug = urlParams.get("collectionSlug");

  const category = urlParams.get("category");
  const advertHeight = Number(urlParams.get("advertHeight"));
  const advertWidth = Number(urlParams.get("advertWidth"));
  const productWidth = Number(urlParams.get("productWidth"));
  const productHeight = Number(urlParams.get("productHeight"));
  const productId = urlParams.get("productId");

  useEffect(() => {
    const query = `*[_type=="product" && mixMatch==true && productId != $productId && 
    advertSize.height == $advertHeight && 
    advertSize.width == $advertWidth && productSize.height == $productHeight && 
    productSize.width == $productWidth && 
    $countryCode in regions[].region->countryCode]{
    ...,"categories": categories[]->{title, "collectionRef": collectionRef->{title}},
    "regions": regions[].region->{countryCode}
  }`;

    const params = {
      advertHeight: advertHeight,
      advertWidth: advertWidth,
      productHeight: productHeight,
      productWidth: productWidth,
      categoryName: category,
      countryCode: process.env.GATSBY_REGION,
      productId: productId,
    };

    sanityClient.fetch(query, params).then((result: any) => {
      setProducts(result);
      setLoaded(true);
    });
  }, []);

  const lastItem = products && products.indexOf(products[products.length - 1]) + 1;

  return (
    <Layout>
      <SEO seoData={seo} />
      <Hero data={hero} />
      <Container width="100%" tabletFlexDirection="column">
        <Container width="30%" tabletWidth="100%">
          <Container
            width="50%"
            flexDirection="column"
            padding="150px 25% 0 25%"
            tabletPadding="0"
            tabletMargin="50px 10% 20px 10%"
            mobileMargin="30px 10% 0px 10%"
          >
            <StyledLink internalLink={{ _type: "collection", slug: { current: collectionSlug } }}>
              Back to {collection}
            </StyledLink>
          </Container>
        </Container>
        <Container margin="0 2% 0 0" flexDirection="column" width="70%" tabletWidth="100%">
          {products && loaded ? (
            <>
              {products.length > 0 && (
                <StandardGridLayout noBorderTop>
                  {products.map((product, index) => {
                    if (product == null || lastItem == null) return null;

                    const { noBorderBottom, noBorderLeft } = calculateGridItemBorders(
                      index,
                      lastItem,
                      products.length,
                    );

                    return (
                      <ProductCard
                        data={product}
                        key={product.id}
                        hasBorderLeft={!noBorderLeft}
                        hasBorderBottom={!noBorderBottom}
                        sanityClientImages
                      />
                    );
                  })}
                </StandardGridLayout>
              )}
            </>
          ) : (
            <img src={assets.loadingIcon} style={{ width: "50px", margin: "130px auto" }} />
          )}
        </Container>
      </Container>
    </Layout>
  );
}

export default MixAndMatch;

export const query = graphql`
  {
    sanityMixMatchPage {
      seo {
        ...sanitySeo
      }
      hero {
        ...sanityHero
      }
    }
  }
`;
